import styled from 'styled-components';

/**
 * example:
 *   ----- text -----
 */
export const TextSurroundedByLines = styled.div<{width?: number}>`
  text-align: center;
  position: relative;
  color: #6b6762;
  padding: 12px 0px;
  &::before,
  &::after {
    content: ' ';
    position: absolute;
    top: calc(50% - 1px);
    height: 1px;
    width: calc(50% - ${({width}) => `${width ? width / 2 : 18}px`});
    left: 0;
    background: #dad8d6;
  }
  &::after {
    left: initial;
    right: 0;
  }
`;
