import {Colors, Button as UIButton} from '@dagster-io/ui-components';
import styled, {css} from 'styled-components';

export const BaseInputStyle = css<{
  invalid?: boolean;
}>`
  background-color: inherit;
  border: none;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: rgb(189 186 183) inset 0px 0px 0px 1px;
  height: 48px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 18px 0px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.00017em;
  user-select: none;
  transition: box-shadow 150ms;
  width: 100%;

  &:focus:not(:focus-visible),
  &:hover {
    box-shadow:
      rgb(189 186 183) inset 0px 0px 0px 1px,
      rgb(0 0 0 / 12%) 0px 2px 12px 0px;
  }
  padding: 14px 8px;
  color: #231f1b;

  img {
    margin-right: 12px;
    width: 16px;
    height: 16px;
  }

  ${({invalid}) => (invalid ? `border: 1px solid red;` : ``)}
`;

export const Button = styled(UIButton)<{$disabled?: boolean}>`
  ${BaseInputStyle}
  &&, &&:hover {
    cursor: pointer;
    text-decoration: none;
    color: ${Colors.Dark};
  }

  ${({$disabled}) => ($disabled ? `&,` : '')}
  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

export const SubmitButton = styled(Button)`
  box-shadow: none;
  border: none;
  background: #231f1b;

  &&,
  &&:hover,
  &&:active {
    color: white;
  }

  &&:disabled {
    opacity: 1;
    cursor: default;
    background: #dad8d6;
    color: #86837f;
    &:hover {
      box-shadow: none;
    }
  }
`;

export const Input = styled.input`
  ${BaseInputStyle};
  padding: 16px 14px;
`;
